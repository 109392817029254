import * as React from "react";
import { Admin, Resource, ListGuesser } from 'react-admin';

import { ProjectList, ProjectShow } from "./projects";

import {
  FirebaseDataProvider,
  FirebaseAuthProvider
} from "react-admin-firebase";

import { firebaseConfig } from './FIREBASE_CONFIG';

const options = {
  logging: true,
}

const authProvider = FirebaseAuthProvider(firebaseConfig, options);
const dataProvider = FirebaseDataProvider(firebaseConfig, options);

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource
      name="projects"
      list={ProjectList}
      show={ProjectShow}
    />
  </Admin>
);

export default App;