
import * as React from "react";

import {
  Datagrid,
  List,
  Show,
  DateField,
  SimpleShowLayout,
  TextField,
  ShowButton,
  ArrayField,
  EmailField,
  UrlField,
} from "react-admin";

export const ProjectList = (props) => {
  return (
    <List {...props} title="List of user project">
      <Datagrid>
        <TextField source="user.fullname" label="Fullname" />
        <EmailField source="user.email" label="Email" />
        <DateField source="registerDate" label="Register date" showTime />
        <ShowButton />
      </Datagrid>
    </List>
  );
}
export const ProjectShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ArrayField source="analysis">
          <Datagrid>
            <DateField source="date" />
            <TextField source="status" />
            <UrlField source="data.downloadURL" />
            <UrlField source="data.email" />
            <ArrayField source="data.answers">
              <Datagrid>
                <TextField source="question" />
                <TextField source="response" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
};